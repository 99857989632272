/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Header from "../header"
import Footer from "./v2/Footer"
import Partners from "../sections/Partners"
import Hero from "../sections/Hero"
import { useAboutAssets } from "../../hooks/about"
import SEO from "../seo"
import CookieConsent from "../CookieConsent"
import Up from "../Up"
import Button from "../Button"

const Main = styled.main`
  background-color: #1a1a1a;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 70px 0 100px 0;

  @media all and (min-width: 769px) {
    background-image: ${props => `url(${props.bg})`};
  }
`
const AltTitleContainer = styled.header`
  text-align: center;
  margin-bottom: 3rem;
  @media all and (min-width: 768px) {
    margin-bottom: 5.5555556rem;
  }
`
const SecondaryTitle = styled.h2`
  font-size: 2.2222222em;
  margin-bottom: 2.77777778rem;
`
const Container = styled.div`
  max-width: 900px;
  margin: 0 auto 100px auto;
  padding: 0 15px;
`
const Accent = styled.span`
  color: #6c9fff;
`
const Article = styled.article`
  .gatsby-resp-image-wrapper {
    margin: 30px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: #6c9fff;
  }

  a {
    text-decoration: none;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
  .gatsby-resp-iframe-wrapper {
    margin-top: 1.45rem;
    margin-bottom: 1.45rem;
  }
`
const ButtonContainer = styled.div`
  margin-top: 50px;
  text-align: center;
`
const StyledButton = styled(Button)`
  font-size: 2rem;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
`

const PostPageLayout = ({ data: { mdx } }) => {
  const { bg } = useAboutAssets()
  return (
    <>
      <SEO
        title={mdx.frontmatter.seoTitle}
        description={mdx.frontmatter.seoMetaDescription}
        ogImage={mdx.frontmatter.cover.src.resize.src}
        slug={mdx.frontmatter.slug}
      />
      <CookieConsent />
      <Up />
      <Header />
      <Hero
        title={
          mdx.frontmatter.type === "legal" ? (
            mdx.frontmatter.title
          ) : (
            <Accent>Blog</Accent>
          )
        }
        bg={mdx.frontmatter.cover.src.fluid.src}
      />
      <Main bg={bg}>
        <Container>
          <Article>
            {mdx.frontmatter.type !== "legal" && (
              <AltTitleContainer className="wow fadeIn">
                <SecondaryTitle>{mdx.frontmatter.title}</SecondaryTitle>
              </AltTitleContainer>
            )}
            <MDXRenderer className="wow fadeIn">{mdx.body}</MDXRenderer>
          </Article>
          {mdx.frontmatter.buttonURL ? (
            <ButtonContainer>
              <StyledButton to={mdx.frontmatter.buttonURL}>
                {mdx.frontmatter.buttonLabel || "Ajánlatkérés"}
              </StyledButton>
            </ButtonContainer>
          ) : null}
        </Container>
        <Partners />
      </Main>
      <Footer color={"#6c9fff"} />
    </>
  )
}

export default PostPageLayout

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
        seoTitle
        seoMetaDescription
        excerpt
        cover {
          src: childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
            resize(width: 900) {
              src
            }
          }
        }
        type
        buttonURL
        buttonLabel
      }
    }
  }
`
